import React, { useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { KeyboardArrowDown } from '@mui/icons-material';
import { KeyboardArrowUp } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { vAPIURL, vBoolDev } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';

const CategoryRevenue = () => {

	const [CategoryRevenue, SetCategoryRevenue] = useState([]);
	const [Loading, setLoading] = useState(false);
	const [vTimer, SetTimer] = useState(0);
	const [vOpen, SetOpen] = React.useState([ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	
	const GetCategoryRevenue = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/ExecutiveHighlight";
			const vData = {
				Action: {
					Value: "Search",
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then((vResponse) => {
					vStatus = vResponse.data.Status;

					for (var i = 0; i < vResponse.data.Message.length; i++) {
						vMessage.push(vResponse.data.Message[i]);
					}

					if (vStatus === 1) {
						SetCategoryRevenue(vResponse.data.Output.Result);
					}
				})
				.catch((vError) => {
					vStatus = 2;
					vMessage.push("Error");
				});
		} catch (vError) {
			vStatus = 2;
			vMessage.push("Error");
		}

		if (vMessage.length > 0) {
			if (vStatus === 1) {
				//toastr.success( vMessage.join( "</br>" ) );
			} else {
				toastr.error(vMessage.join("</br>"));
			}
		}
		setLoading(false);
	};

	const BorderLinearProgressGreen = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.mode === 'light' ? '#00b050' : '#308fe8',
		},
	}));

	const BorderLinearProgressBlue = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.mode === 'light' ? '#0070c0' : '#308fe8',
		},
	}));

	const BorderLinearProgressGray = styled(LinearProgress)(({ theme }) => ({
		height: 10,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
		},
		[`& .${linearProgressClasses.bar}`]: {
			borderRadius: 5,
			backgroundColor: theme.palette.mode === 'light' ? '#c6e0b4' : '#308fe8',
		},
	}));
	
	function HandleOpen(index){
		const vOpenTemp = vOpen.map((c, i) => {
			if (i == index) {
				if( c === 0 ){
					return 1;
				}
				else{
					return 0;
				}
			} else {
				return c;
			}
		});
		SetOpen(vOpenTemp);
	}

	// vResponse.sort((a, b) => parseFloat(b.Revenue) - parseFloat(a.Location));
	// vResponse.sort((a, b) => parseFloat(b.Location) - parseFloat(a.Revenue));
	
	const DataTempProd = {
		"Result": [
			{
				"TypeRevenue": "Golf Rate",
				"Order": "1",
				"TotalRevenue": "255.245.000,00",
				"Percentage": 65.8,
				"Child": []
			},
			{
				"TypeRevenue": "Restaurant",
				"Order": "2",
				"TotalRevenue": "77.239.066,00",
				"Percentage": 19.9,
				"Child": [
					{
						"TotalRevenue": "33.985.189,00",
						"Location": "Restaurant",
						"Order": "5",
						"Percentage": 44,
						"Child": []
					},
					{
						"TotalRevenue": "17.764.985,00",
						"Location": "Founder Lounge",
						"Order": "4",
						"Percentage": 33,
						"Child": []
					},
					{
						"TotalRevenue": "25.488.891,00",
						"Location": "Suvarna Practice Range",
						"Order": "3",
						"Percentage": 23,
						"Child": [
							{
								"TotalRevenue": "3.313.555,00",
								"Location": "VIP3 - BAY 47",
								"Percentage": 13
							},
							{
								"TotalRevenue": "5.862.444,00",
								"Location": "VIP1 - Bay 45",
								"Percentage": 23
							},
							{
								"TotalRevenue": "2.294.000,00",
								"Location": "VIP2 - BAY 46",
								"Percentage": 9
							},
							{
								"TotalRevenue": "10.195.556,00",
								"Location": "VIP4 - BAY 48",
								"Percentage": 40
							},
							{
								"TotalRevenue": "3.823.333,00",
								"Location": "VIP5 - BAY 49",
								"Percentage": 15
							}
						]
					}
				]
			},
			{
				"TypeRevenue": "F&B Resto",
				"Order": "6",
				"TotalRevenue": "38.043.122,00",
				"Percentage": 9.8,
				"Child": [
					{
						"TotalRevenue": "4.945.605,00",
						"Location": "CoolBox",
						"Order": "10",
						"Percentage": 13,
						"Child": []	
					},
					{
						"TotalRevenue": "8.749.918,00",
						"Location": "Mobile Kios",
						"Order": "11",
						"Percentage": 23,
						"Child": []
					},
					{
						"TotalRevenue": "3.423.880,00",
						"Location": "Shelter",
						"Order": "7",
						"Percentage": 9,
						"Child": [
							{
								"TotalRevenue": "1.574.984,00",
								"Location": "Shelter 6",
								"Percentage": 46
							},
							{
								"TotalRevenue": "1.848.895,00",
								"Location": "Shelter 7",
								"Percentage": 54
							}
						]
					},
					{
						"TotalRevenue": "15.217.248,00",
						"Location": "F&B Corporate Club House",
						"Order": "9",
						"Percentage": 40,
						"Child": [
							{
								"TotalRevenue": "1.978.242,00",
								"Location": "CCH-A",
								"Percentage": 13
							},
							{
								"TotalRevenue": "608.689,00",
								"Location": "CCH-B",
								"Percentage": 4
							},
							{
								"TotalRevenue": "1.826.069,00",
								"Location": "CCH-C",
								"Percentage": 12
							},
							{
								"TotalRevenue": "2.130.414,00",
								"Location": "CCH-D",
								"Percentage": 14
							},
							{
								"TotalRevenue": "3.043.449,00",
								"Location": "CCH-E",
								"Percentage": 20
							},
							{
								"TotalRevenue": "2.282.587,00",
								"Location": "CCH-M",
								"Percentage": 15
							},
							{
								"TotalRevenue": "1.065.207,00",
								"Location": "CCH-P",
								"Percentage": 7
							},
							{
								"TotalRevenue": "2.282.587,00",
								"Location": "CCH-Q",
								"Percentage": 15
							}
						]
					},
					{
						"TotalRevenue": "5.706.468,00",
						"Location": "F&B VIP",
						"Order": "8",
						"Percentage": 15,
						"Child": [
							{
								"TotalRevenue": "855.970,00",
								"Location": "VIP1 YOGYAKARTA",
								"Percentage": 15
							},
							{
								"TotalRevenue": "798.905,00",
								"Location": "VIP2 DENPASAR",
								"Percentage": 14
							},
							{
								"TotalRevenue": "913.034,00",
								"Location": "VIP3 BANDA ACEH",
								"Percentage": 16
							},
							{
								"TotalRevenue": "1.027.164,00",
								"Location": "VIP4 MANADO",
								"Percentage": 18
							},
							{
								"TotalRevenue": "1.141.293,00",
								"Location": "VIP5 JAKARTA",
								"Percentage": 20
							},
							{
								"TotalRevenue": "970.099,00",
								"Location": "VVIP MINANGKABAU",
								"Percentage": 17
							}
						]
					}
				]
			},
			{
				"TypeRevenue": "Room Rate",
				"Order": "12",
				"TotalRevenue": "17.459.396,00",
				"Percentage": 4.5,
				"Child": []
			},
			{
				"TypeRevenue": "Total",
				"Order": "13",
				"TotalRevenue": "387.986.584,00",
				"Percentage": 100,
				"Child": []
			}
		]
	}

	const DataTempDev = {
		"Result": [
			{
				"TypeRevenue": "GOLF RATE",
				"Order": "1",
				"TotalRevenue": "255.245.000,00",
				"Percentage": 65.8,
				"Child": []
			},
			{
				"TypeRevenue": "RESTAURANT (GROUP)",
				"Order": "2",
				"TotalRevenue": "77.239.066,00",
				"Percentage": 19.9,
				"Child": [
					{
						"TotalRevenue": "33.985.189,00",
						"Location": "MAIN RESTAURANT",
						"Order": "5",
						"Percentage": 44,
						"Child": []
					},
					{
						"TotalRevenue": "17.764.985,00",
						"Location": "NUSANTARA LOUNGE",
						"Order": "4",
						"Percentage": 33,
						"Child": []
					},
					{
						"TotalRevenue": "25.488.891,00",
						"Location": "DRIVING RANGE (GROUP)",
						"Order": "3",
						"Percentage": 23,
						"Child": [
							{
								"TotalRevenue": "3.058.666,00",
								"Location": "VIP BAY #1",
								"Percentage": 12
							},
							{
								"TotalRevenue": "3.568.444,00",
								"Location": "VIP BAY #2",
								"Percentage": 14
							}, 
							{
								"TotalRevenue": "2.548.889,00",
								"Location": "VIP BAY #3",
								"Percentage": 10
							},
							{
								"TotalRevenue": "4.078.222,00",
								"Location": "VIP BAY #4",
								"Percentage": 16
							},
							{
								"TotalRevenue": "2.039.111,00",
								"Location": "BAY #1",
								"Percentage": 8
							},
							{
								"TotalRevenue": "3.313.555,00",
								"Location": "BAY #2",
								"Percentage": 13
							},
							{
								"TotalRevenue": "1.784.222,00",
								"Location": "BAY #3",
								"Percentage": 7
							},
							{
								"TotalRevenue": "1.274.444,00",
								"Location": "BAY #4",
								"Percentage": 5
							},
							{
								"TotalRevenue": "3.823.333,00",
								"Location": "BAY #5",
								"Percentage": 15
							}
						]
					}
				]
			},
			{
				"TypeRevenue": "OUTDOOR KIOS (GROUP)",
				"Order": "6",
				"TotalRevenue": "38.043.122,00",
				"Percentage": 9.8,
				"Child": [
					{
						"TotalRevenue": "4.945.605,00",
						"Location": "COOLBOX",
						"Order": "10",
						"Percentage": 13,
						"Child": []	
					},
					{
						"TotalRevenue": "8.749.918,00",
						"Location": "MOBILE KIOS",
						"Order": "11",
						"Percentage": 23,
						"Child": []
					},
					{
						"TotalRevenue": "3.423.880,00",
						"Location": "SHELTER (GROUP)",
						"Order": "7",
						"Percentage": 9,
						"Child": [
							{
								"TotalRevenue": "1.574.984,00",
								"Location": "SHELTER A",
								"Percentage": 46
							},
							{
								"TotalRevenue": "1.848.895,00",
								"Location": "SHELTER B",
								"Percentage": 54
							}
						]
					},
					{
						"TotalRevenue": "5.706.468,00",
						"Location": "F&B VIP (GROUP)",
						"Order": "8",
						"Percentage": 15,
						"Child": [
							{
								"TotalRevenue": "855.970,00",
								"Location": "VIP1",
								"Percentage": 15
							},
							{
								"TotalRevenue": "798.905,00",
								"Location": "VIP2",
								"Percentage": 14
							},
							{
								"TotalRevenue": "913.034,00",
								"Location": "VIP3",
								"Percentage": 16
							},
							{
								"TotalRevenue": "1.027.164,00",
								"Location": "VIP4",
								"Percentage": 18
							},
							{
								"TotalRevenue": "1.141.293,00",
								"Location": "VIP5",
								"Percentage": 20
							},
							{
								"TotalRevenue": "970.099,00",
								"Location": "VIP6",
								"Percentage": 17
							}
						]
					}
				]
			},
			{
				"TypeRevenue": "ROOM RATE",
				"Order": "12",
				"TotalRevenue": "17.459.396,00",
				"Percentage": 4.5,
				"Child": []
			},
			{
				"TypeRevenue": "TOTAL",
				"Order": "13",
				"TotalRevenue": "387.986.584,00",
				"Percentage": 100,
				"Child": []
			}
		]
	}

	let DataDummy = []

	if ( vBoolDev == true ){
		DataDummy = DataTempDev.Result
	}
	else{
		DataDummy = DataTempProd.Result
	}

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		GetCategoryRevenue()
	// 		SetTimer( 16000 );
	// 	}, vTimer);
	// 	return () => clearTimeout(timer);
	// })

	useEffect(() => {
		GetCategoryRevenue();
	}, []);

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<h3 className="card-title text-success font-weight-bold">
					CATEGORY REVENUE
				</h3>
				<div className="card-tools">
					<ul className="nav nav-pills ml-auto">
						<li className="nav-item text-info font-weight-bold">
							DAILY
						</li>
					</ul>
				</div>
			</div>
			{Loading ?
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"></i>
				</div>
				:
				<TableContainer component={Paper}>
					<TableRow>
						<TableHead 
							sx={{ 
								height: 10
							}}
						>
							{DataDummy.map((Value1, Index1) => (
								<TableRow>
									<TableHead>
										<TableCell
											sx={{ 
												width: 400
												, fontWeight: Index1 === DataDummy.length - 1 ? 'bold' : 'normal'
											}}
										>
											{Value1.TypeRevenue}
										</TableCell>
										<TableCell 
											sx={{ 
												width: 66 
												, fontWeight: Index1 === DataDummy.length - 1 ? 'bold' : 'normal'
											}}
										>
											{
												Value1.Child.length === 0 
													? 
														null 
													: 
													<IconButton
														aria-label="expand row"
														size="small"
														onClick={() => HandleOpen(Value1.Order)}
													>
														{vOpen[Value1.Order] === 1 ? <KeyboardArrowUp /> : <KeyboardArrowDown />} 
													</IconButton>
											}
										</TableCell>
										<TableCell 
											sx={{ 
												width: 340
												, fontWeight: Index1 === DataDummy.length - 1 ? 'bold' : 'normal'
											}}
										>
											<BorderLinearProgressGreen variant="determinate" value={Value1.Percentage} />
										</TableCell>
										<TableCell 
											sx={{ 
												width: 100
												, fontWeight: Index1 === DataDummy.length - 1 ? 'bold' : 'normal' 
											}}
										>
											{Value1.Percentage} %
										</TableCell>
										<TableCell 
											sx={{ 
												width: 300
												, fontWeight: Index1 === DataDummy.length - 1 ? 'bold' : 'normal'
											}} 
											align="right"
										>
											Rp. {Value1.TotalRevenue}
										</TableCell>
									</TableHead>
									<TableHead>
										<TableCell 
											style={{ 
												padding: 0 
											}} 
											colSpan={5}
										>
											<Collapse 
												in={vOpen[Value1.Order] === 1} 
												timeout="auto" 
												unmountOnExit
											>
												<Table>
													{(Value1.Child).map((Value2) => (
														<TableHead>
															<TableRow>
																<TableCell 
																	sx={{ 
																		width: 400 
																	}}
																>
																	{Value2.Location}
																</TableCell>
																<TableCell 
																	sx={{ 
																		width: 66
																	}}
																>
																	{
																		Value2.Child.length === 0 
																			? 
																				null 
																			: 
																				<IconButton
																					aria-label="expand row1"
																					size="small"
																					onClick={() => HandleOpen(Value2.Order)}
																				>
																					{vOpen[Value2.Order] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
																				</IconButton>
																	}
																</TableCell>
																<TableCell 
																	sx={{ 
																		width: 340 
																	}}
																>
																	<BorderLinearProgressBlue variant="determinate" value={Value2.Percentage}/>
																</TableCell>
																<TableCell 
																	sx={{
																		width: 100
																	}}
																>
																	{Value2.Percentage} %
																</TableCell>
																<TableCell 
																	sx={{ 
																		width: 300
																	}} 
																	align="right"
																>
																	Rp. {Value2.TotalRevenue}
																</TableCell>
															</TableRow>
															<TableRow>
																<TableCell 
																	style={{ 
																		padding: 0
																	}} 
																	colSpan={5}
																>
																	<Collapse 
																		in={vOpen[Value2.Order]} 
																		timeout="auto" 
																		unmountOnExit
																	>
																		<Table>
																			{(Value2.Child).map((Value3) => (
																				<TableHead>
																					<TableRow>
																						<TableCell 
																							sx={{ 
																								width: 400
																							}}
																						>
																							{Value3.Location}
																						</TableCell>
																						<TableCell 
																							sx={{ 
																								width: 66 
																							}}
																						>
																						</TableCell>
																						<TableCell 
																							sx={{
																								width: 340
																							}}
																						>
																							<BorderLinearProgressGray variant="determinate" value={Value3.Percentage} />
																						</TableCell>
																						<TableCell 
																							sx={{ 
																								width: 105
																							}}
																						>
																							{Value3.Percentage} %
																						</TableCell>
																						<TableCell 
																							sx={{ 
																								width: 300
																							}}
																							align="right"
																						>
																							Rp. {Value3.TotalRevenue}
																						</TableCell>
																					</TableRow>
																				</TableHead>
																			))}
																		</Table>
																	</Collapse>
																</TableCell>
															</TableRow>
														</TableHead>
													))}
												</Table>
											</Collapse>
										</TableCell>
									</TableHead>
								</TableRow>
							))}
						</TableHead>
					</TableRow>
				</TableContainer>
			}
		</div>
	);
}

export default CategoryRevenue