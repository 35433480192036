import "toastr/build/toastr.min.css";
import axios from "axios";

import Golf_BG from './../Assets/Golf_BG.jpg';
import Travel_BG from './../Assets/Travel_BG.png';
import Askara_BG from './../Assets/Askara_BG.png';

import Golf_Logo from './../Assets/Golf_Logo.png';
import Travel_Logo from './../Assets/Travel_Logo.jpg';
import Askara_Logo from './../Assets/Askara_Logo.png';

import React, { useState } from "react";
import toastr from "toastr";
import { Button, TextField, Typography } from "@mui/material";
import { vAPIURL, vBoolGolf, vBoolTravel } from './../Global';

const ChangePassword = () => {
	const [ vLoading, SetLoading ] = useState( false );
	const [ vOldPassword, SetOldPassword ] = useState( "" );
	const [ vNewPassword, SetNewPassword ] = useState( "" );
	const [ vConfirmNewPassword, SetConfirmNewPassword ] = useState( "" );

	let vBG = Askara_BG;
	let vLogo = Askara_Logo;
	let vTitle = "ASKARA";

	if( vBoolGolf && vBoolTravel ){
	}
	else if( vBoolGolf ){
		vBG = Golf_BG;
		vLogo = Golf_Logo;
		vTitle = "GM360";
	}
	else if( vBoolTravel ){
		vBG = Travel_BG;
		vLogo = Travel_Logo;
		vTitle = "ITM";
	}

	const FPasswordChange = async () => {
		SetLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Password/Change";
			const vData = {
				Action: {
					Value: "Change",
				},
				Input: {
					OldPassword: {
						Value: vOldPassword,
					},
					NewPassword: {
						Value: vNewPassword,
					},
					ConfirmNewPassword: {
						Value: vConfirmNewPassword,
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${ localStorage.getItem( "Authentication" ) }`,
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							localStorage.clear();
							window.location.replace( "/Sign/In" );
						}
					}
				)
				.catch(
					( err ) => {
						vStatus = 2;
						vMessage.push( "Error System" );
					}
				);
		}catch( error ){
			vStatus = 2;
			vMessage.push( "Error System" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		SetLoading( false );
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundImage: `url(${vBG})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100vh"
			}}
		>
			<div align="center">
				<div className="login-box">
					<div className="card card-outline card-primary">
						<div className="card-header text-center">
							<div className="row">
								<div className="col-2">
									<img
										src={vLogo}
										className="brand-image img-circle elevation-3"
										style={{ height: 60, opacity: ".8" }}
									/>
								</div>
								<div className="col-8">
									<Typography sx={{ mt: 2 }} variant="h5">
										{vTitle} Dashboard
									</Typography>
								</div>
							</div>
						</div>
						<div className="card-body">
							<h6>Change Your Password</h6>
							<TextField
								variant="outlined"
								margin="normal"
								default=""
								fullWidth
								id="OldPassword"
								name="OldPassword"
								label="Old Password"
								type="password"
								onChange={ ( e ) => SetOldPassword( e.target.value ) }
							/>
							<TextField
								variant="outlined"
								margin="normal"
								default=""
								fullWidth
								id="NewPassword"
								name="NewPassword "
								label="New Password"
								type="password"
								onChange={ ( e ) => SetNewPassword( e.target.value ) }
							/>
							<TextField
								variant="outlined"
								margin="normal"
								default=""
								fullWidth
								id="ConfirmNewPassword"
								name="ConfirmNewPassword"
								label="Confirm New Password"
								type="password"
								onChange={ ( e ) => SetConfirmNewPassword( e.target.value ) }
							/>
							{
								vLoading ?
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
									>
										<center>
											<i className="fas fa-1x fa-sync-alt fa-spin"/>
										</center>
									</Button>
								:
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										onClick={FPasswordChange}
									>
										Change
									</Button>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
