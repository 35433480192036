import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Loading from './LazyLoading';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format';
import { startOfWeek,endOfWeek } from 'date-fns';

ChartJS.register(...registerables )

const WeeklyRevenueDetail = lazy(() => import('./WeeklyRevenueDetail.js'));

const WeeklyRevenueReport = () => {
	const [ OpenChart, SetOpenChart] = useState(false);
	const [ vWeeklyLoading, SetWeeklyLoading ] = useState( true );
	const [ vWeeklyData, SetWeeklyData ] = useState( [] );
	const [ vWeeklyDetailStartDate, SetWeeklyDetailStartDate ] = useState("");
	const [ vWeeklyDetailEndDate, SetWeeklyDetailEndDate ] = useState("");
	const [ vWeeklyDetailWeek, SetWeeklyDetailWeek ] = useState("");
	let EndDateDefault = new Date( vGlobalDateNow );
	let EndDateWeek = EndDateDefault.getDay();
	let AddEndDate = EndDateWeek < 6 ? 6 - EndDateWeek : 0;
	EndDateDefault.setDate(EndDateDefault.getDate() + AddEndDate);
	const [ vSearchPlayerEndDate, SetSearchPlayerEndDate ] = useState(EndDateDefault);
	let Now = new Date( vGlobalDateNow );
	let StartWeekDefault = new Date( Now );
	let SubStartDate = (Now.getDay() + 7) % 7 + 7 * 4;
	StartWeekDefault.setDate(Now.getDate() - SubStartDate);
	const [ vSearchPlayerStartDate, SetSearchPlayerStartDate ] = useState(StartWeekDefault);

	const FWeekly = async () => {
		SetWeeklyLoading( true );
		let vStatus = 1;
		let vMessage = [];
		try{
			const vURL = vAPIURL + "/Golf/Revenue/Weekly";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate: {
						Value: format( vSearchPlayerStartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( vSearchPlayerEndDate, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}
						if( vStatus === 1 ){
							SetWeeklyData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		SetWeeklyLoading( false );
	}

	let vWeeklyBarData = {
		labels: vWeeklyData.map( row => row.Weekly ),
		datasets: [
			{
				label: 'Total in Million',
				data: vWeeklyData.map( row => row.TotalInMillion ),
				backgroundColor: [
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
				],
				borderColor: [
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
				],
				borderWidth: 1,
			},
		],
	};

	let vWeeklyBarOption = {
		maintainAspecRatio: true
		, responsive : true
		, scales: {
			x: {
				beginAtZero: true
			}
			, y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 10,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			datalabels: {
				anchor: 'end',
				align: 'start',
				display: false
			}
		}
		, onClick: (e, activeEls) => {
			let vDataIndex = activeEls[ 0 ].index;
			SetWeeklyDetailStartDate(vWeeklyData[ vDataIndex ].StartPeriod);
			SetWeeklyDetailEndDate(vWeeklyData[ vDataIndex ].EndPeriod);
			SetWeeklyDetailWeek(e.chart.data.labels[ vDataIndex ]);
			SetOpenChart(true);
		},
		onHover: (e, ChartElement) => {
			e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
		},
	}

	useEffect(() => {
		FWeekly();
	}, []);

	return (
		<>
			<div className="col-sm-6">
				<section>
					<div className="card bg-gradient-light">
						<div className="card-header">
							<h3 className="card-title text-success font-weight-bold">
								WEEKLY REVENUE CHART (ALL)
							</h3>
							<div className="card-tools">
								<ul className="nav nav-pills ml-auto">
									<li className="nav-item text-info font-weight-bold">
									</li>
								</ul>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-sm-4">
									<div className="form-group">
										<label>
											START DATE
										</label>
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={vSearchPlayerStartDate}
											onChange={e => SetSearchPlayerStartDate(startOfWeek(e, { weekStartsOn: 0 }))}
											className="form-control"
											value={vSearchPlayerStartDate}
										/>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-group">
										<label>
											END DATE
										</label>
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={vSearchPlayerEndDate}
											onChange={e => SetSearchPlayerEndDate(endOfWeek(e, { weekStartsOn: 0 }))}
											className="form-control"
											value={vSearchPlayerEndDate}
										/>
									</div>
								</div>
								<div className="col-sm-4">
									<button
										onClick={FWeekly}
										type="submit"
										className="btn btn-primary float-right"
										style={{ marginTop:"31px" }}
									>
										<i className="fas fa-search"/>
										&nbsp;
										{vWeeklyLoading ?
												"Please wait..."
											:
												"Search"
										}
									</button>
								</div>
							</div>
							<div className="tab-content p-0">
								{ vWeeklyLoading ?
										<div className="d-flex justify-content-center">
											<i className="fas fa-3x fa-sync-alt fa-spin"/>
										</div>
									:
										<div>
											<Bar
												data={vWeeklyBarData}
												plugins={[ChartDataLabels]}
												options={vWeeklyBarOption}
												height={200}
											/>
										</div>
								}
							</div>
						</div>
					</div>
				</section>
			</div>
			{OpenChart ? (
				<Suspense fallback={<Loading />}>
					<WeeklyRevenueDetail
						WeeklyDetailStartDate={vWeeklyDetailStartDate}
						WeeklyDetailEndDate={vWeeklyDetailEndDate}
						WeeklyDetailWeek={vWeeklyDetailWeek}
						StartWeek={vSearchPlayerStartDate}
						EndWeek={vSearchPlayerEndDate}
					/>
				</Suspense>
				) : ""
			}
		</>
	)
}

export default WeeklyRevenueReport