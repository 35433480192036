import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Loading from './LazyLoading';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format';
import { startOfMonth, endOfMonth } from 'date-fns';

ChartJS.register(...registerables )

const MonthlyRevenueDetail = lazy(() => import('./MonthlyRevenueDetail.js'));

const MonthlyRevenueReport = () => {

	const NewDate = new Date( vGlobalDateNow );

	const [ OpenChart, SetOpenChart] = useState(false);
	const [ vMonthlyLoading, SetMonthlyLoading ] = useState( true )
	const [ vMonthlyData, SetMonthlyData ] = useState( [] )
	const [ vMonthlyDetailDate, SetMonthlyDetailDate ] = useState("");
	const MonthEnd = endOfMonth(NewDate);
	const [ vSearchPlayerEndDate, SetSearchPlayerEndDate ] = useState(MonthEnd);
	const MonthStart = subtractMonths(startOfMonth(NewDate), 13);
	const [ vSearchPlayerStartDate, SetSearchPlayerStartDate ] = useState(MonthStart);

	function subtractMonths(date, months) {
		const newDate = new Date( date );
		newDate.setMonth(date.getMonth() - months);
		return newDate;
	}

	const FMonthly = async () => {
		SetMonthlyLoading( true );
		let vStatus = 1;
		let vMessage = [];
		try{
			const vURL = vAPIURL + "/Golf/Revenue/Monthly";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate: {
						Value: format( vSearchPlayerStartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( vSearchPlayerEndDate, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}
						if( vStatus === 1 ){
							SetMonthlyData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}
		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		SetMonthlyLoading( false );
	}

	let vMonthlyBarData = {
		labels: vMonthlyData.map( row => row.Period ),
		datasets: [
			{
				label: 'Total in Million',
				data: vMonthlyData.map( row => row.TotalInMillion ),
				backgroundColor: [
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
				],
				borderColor: [
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
				]
				, borderWidth: 1,
			},
		],
	};

	let vMonthlyBarOption = {
		maintainAspecRatio: true,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true,
			},
			y: {
				beginAtZero: true,
			},
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 10,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			datalabels: {
				anchor: 'end',
				align: 'start',
				display: false
			},
		},
		onClick: (e, activeEls) => {
			let vDataIndex = activeEls[ 0 ].index;
			SetMonthlyDetailDate(e.chart.data.labels[ vDataIndex ]);
			SetOpenChart(true)
		},
		onHover: (e, ChartElement) => {
			e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
		},
	}

	useEffect(() => {
		FMonthly();
	}, []);

	const renderMonthContent = (month, shortMonth, longMonth) => {
		return <span>{shortMonth}</span>;
	};

	return (
		<>
			<div className="col-sm-6">
				<section>
					<div className="card bg-gradient-light">
						<div className="card-header">
							<h3 className="card-title text-success font-weight-bold">
								MONTHLY REVENUE CHART (ALL)
							</h3>
							<div className="card-tools">
								<ul className="nav nav-pills ml-auto">
									<li className="nav-item text-info font-weight-bold">
									</li>
								</ul>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-sm-3">
									<div className="form-group">
										<label>
											Start Date
										</label>
										<DatePicker
											dateFormat="MMM-yyyy"
											renderMonthContent={renderMonthContent}
											showMonthYearPicker
											selected={vSearchPlayerStartDate}
											onChange={e => SetSearchPlayerStartDate(startOfMonth(e))}
											className="form-control"
											value={vSearchPlayerStartDate}
										/>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-group">
										<label>
											End Date
										</label>
										<DatePicker
											dateFormat="MMM-yyyy"
											selected={vSearchPlayerEndDate}
											renderMonthContent={renderMonthContent}
											showMonthYearPicker
											onChange={e => SetSearchPlayerEndDate(endOfMonth(e))}
											className="form-control"
											value={vSearchPlayerEndDate}
										/>
									</div>
								</div>
								<div className="col-sm-6">
									<button
										onClick={FMonthly}
										type="submit"
										className="btn btn-primary float-right"
										style={{ marginTop:"31px" }}
									>
										<i className="fas fa-search"/>
										&nbsp;
										{vMonthlyLoading ?
												"Please wait..."
											:
												"Search"
										}
									</button>
								</div>
							</div>
							<div className="tab-content p-0">
								<div className="row">
									<div className="col-xl-6">
									</div>
								</div>
								{
									vMonthlyLoading?
										<div className="d-flex justify-content-center">
											<i className="fas fa-3x fa-sync-alt fa-spin"/>
										</div>
									:
										<div>
											<Bar
												data={vMonthlyBarData}
												plugins={[ChartDataLabels]}
												options={vMonthlyBarOption}
												height={200}
											/>
										</div>
								}
							</div>
						</div>
					</div>
				</section>
			</div>
			{OpenChart ? (
				<Suspense fallback={<Loading />}>
					<MonthlyRevenueDetail 
						MonthlyDetailDate={vMonthlyDetailDate}
						MonthStartDate={vSearchPlayerStartDate}
						MonthEndDate={vSearchPlayerEndDate}
					/>
				</Suspense>
				) : ""
			}
		</>
	)
}

export default MonthlyRevenueReport