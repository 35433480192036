import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Loading from './LazyLoading';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';

ChartJS.register(...registerables )

const DailyRevenueDetail = lazy(() => import('./DailyRevenueDetail.js'));

const DailyRevenueReport = () => {
	const [ OpenChart, SetOpenChart] = useState(false);
	const [ vDailyLoading, SetDailyLoading ] = useState( true )
	const [ vDailyData, SetDailyData ] = useState( [] )
	const [ vDailyDetailDate, SetDailyDetailDate ] = useState( "" )
	const [ vSearchPlayerEndDate, SetSearchPlayerEndDate ] = useState( new Date( vGlobalDateNow ) );
	const [ vSearchPlayerStartDate, SetSearchPlayerStartDate ] = useState( new Date( vSearchPlayerEndDate.getTime() - ( 7 * 24 * 60 * 60 * 1000 ) ) );

	const FDaily = async () => {
		SetDailyLoading( true );
		let vStatus = 1;
		let vMessage = [];
		try{
			const vURL = vAPIURL + "/Golf/Revenue/Daily";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate: {
						Value: format( vSearchPlayerStartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( vSearchPlayerEndDate, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}
						if( vStatus === 1 ){
							SetDailyData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
			}catch( vError ){
				vStatus = 2;
				vMessage.push( "Error" );
			}
			if( vMessage.length > 0 ){
				if( vStatus === 1 ){
					//toastr.success( vMessage.join( "</br>" ) );
				}
				else{
					toastr.error( vMessage.join( "</br>" ) );
				}
			}
		SetDailyLoading( false );
	}

	let vDailyBarData = {
		labels: vDailyData.map( row => row.Date ),
		datasets: [
			{
				label: 'Total in Million',
				data: vDailyData.map( row => row.TotalInMillion ),
				backgroundColor: [
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)'
				],
				borderColor: [
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(255, 99, 132, 0.5)'
				],
				borderWidth: 1, 
			},
		],
	};

	let vDailyBarOption = {
		maintainAspecRatio: true,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true,
			},
			y: {
				beginAtZero: true,
			},
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 10,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			datalabels: {
				anchor: 'end',
				align: 'start',
				display: false
			},
		},
		onClick: (e, activeEls) => {
			let vDataIndex = activeEls[ 0 ].index;
			SetDailyDetailDate(e.chart.data.labels[ vDataIndex ]);
			SetOpenChart(true);
		},
		onHover: (e, ChartElement) => {
			e.native.target.style.cursor = ChartElement[0] ? 'pointer' : 'default'
		},
	}

	useEffect(() => {
		FDaily();
	}, []);

	return (
		<>
			<div className="col-sm-6">
				<section>
					<div className="card bg-gradient-light">
						<div className="card-header">
							<h3 className="card-title text-success font-weight-bold">
								DAILY REVENUE CHART (ALL)
							</h3>
							<div className="card-tools">
								<ul className="nav nav-pills ml-auto">
									<li className="nav-item text-info font-weight-bold"/>
								</ul>
							</div>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-sm-4">
									<div className="form-group">
										<label>
											START DATE
										</label>
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={ vSearchPlayerStartDate }
											onChange={e => SetSearchPlayerStartDate(e)}
											className="form-control"
											value={ vSearchPlayerStartDate }
										/>
									</div>
								</div>
								<div className="col-sm-4">
									<div className="form-group">
										<label>
											END DATE
										</label>
										<DatePicker
											dateFormat="dd-MMM-yyyy"
											selected={ vSearchPlayerEndDate }
											onChange={e => SetSearchPlayerEndDate(e)}
											className="form-control"
											value={ vSearchPlayerEndDate }
										/>
									</div>
								</div>
								<div className="col-sm-4">
									<button
										onClick={FDaily}
										type="submit"
										className="btn btn-primary float-right"
										style={{ marginTop:"31px" }}
									>
										<i className="fas fa-search"/>
										&nbsp;
										{vDailyLoading ?
												"Please wait..."
											:
												"Search"
										}
									</button>
								</div>
							</div>
							<div className="tab-content p-0">
								{vDailyLoading ?
										<div className="d-flex justify-content-center">
											<i className="fas fa-3x fa-sync-alt fa-spin"/>
										</div>
									:
										<div>
											<Bar
												data={vDailyBarData}
												plugins={[ChartDataLabels]}
												options={vDailyBarOption}
												height={200}
											/>
										</div>
								}
							</div>
						</div>
					</div>
				</section>
			</div>
			{OpenChart ? (
				<Suspense fallback={<Loading />}>
					<DailyRevenueDetail 
						DailyDetailDate={vDailyDetailDate}
						DailyStartDate={vSearchPlayerStartDate}
						DailyEndDate={vSearchPlayerEndDate}
					/>
				</Suspense>
				) : ""
			}
		</>
	)
}

export default DailyRevenueReport