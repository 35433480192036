import React, { useEffect, useState } from 'react'
import { vAPIURL, vGlobalDateNow } from '../../Global'
import axios from 'axios';
import toastr from 'toastr';
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { startOfWeek, format } from 'date-fns';

const CancelItemTableService = () => {
	const [CancelItem, SetCancelItem] = useState([])
	const [loading, setLoading] = useState(false)
	let StartDate = format( startOfWeek( new Date( vGlobalDateNow ), { weekStartsOn: 1 } ), 'dd MMM' )
	let EndDate = format( new Date( vGlobalDateNow ), 'dd MMM' )

	const GetCancelItem = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/FnB/CancelItem";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Type: {
						Value: "Waiter",
					},
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vStatus === 1 ){
							SetCancelItem( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		setLoading(false)
	}

	useEffect(() => {
		GetCancelItem()
	}, [])  

	let ChangeOrder = CancelItem.map(Value => Value.ChangeOrder)
	
	let SumChangeOrder = 0
	ChangeOrder.forEach(Value => {
		SumChangeOrder += parseInt(Value);
	});
	ChangeOrder.push(SumChangeOrder);

	let DoubleOrder = CancelItem.map(Value => Value.DoubleOrder)

	let SumDoubleOrder = 0
	DoubleOrder.forEach(Value => {
		SumDoubleOrder += parseInt(Value);
	});
	DoubleOrder.push(SumDoubleOrder);

	let WrongInput = CancelItem.map(Value => Value.WrongInput)

	let SumWrongInput = 0
	WrongInput.forEach(Value => {
		SumWrongInput += parseInt(Value);
	});
	WrongInput.push(SumWrongInput);

	let Other = CancelItem.map(Value => Value.Other)

	let SumOther = 0
	Other.forEach(Value => {
		SumOther += parseInt(Value);
	});
	Other.push(SumOther);

	const HeaderTable = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'TOTAL']

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills">
					<li className="nav-item text-info font-weight-bold">
						WEEKLY FBS ORDER CANCELATION TABLE
					</li>
				</ul>
				<ul className="nav nav-pills">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div className="text-info">
					{StartDate} - {EndDate}
				</div>
			</div>
			<div className="card-body">
				<div className="tab-content p-0 ">
					{
						loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div>
							<TableContainer>
								<Table aria-label="simple table">
									<TableHead>
										<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }}></TableCell>
										{HeaderTable.map((Value) => (
											<TableCell sx={{ border: 1, width: 100, fontWeight: 'bold', padding: 0.5 }} align='center'>{Value}</TableCell>
										))}
									</TableHead>
									<TableHead>
										<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }}>CHANGE ORDER</TableCell>
										{ChangeOrder.map((Value, index) => (
											<TableCell 
												sx={{ 
													border: 1
													, width: 100
													, padding: 0.5
													, fontWeight:(index === 7 ? 'bold' : '')
												}}
												align={index === 7 ? 'right' : 'center'}
											>
												{Value}
											</TableCell>
										))}
									</TableHead>
									<TableHead>
										<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">DOUBLE ORDER</TableCell>
										{DoubleOrder.map((Value, index) => (
											<TableCell 
												sx={{ 
													border: 1
													, width: 100
													, padding: 0.5
													, fontWeight:(index === 7 ? 'bold' : '')
												}}
												align={index === 7 ? 'right' : 'center'}
											>
												{Value}
											</TableCell>
										))}
									</TableHead>
									<TableHead>
										<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5}} align="left" variant="head">WRONG INPUT</TableCell>
										{WrongInput.map((Value, index) => (
											<TableCell 
												sx={{ 
													border: 1
													, width: 100
													, padding: 0.5
													, fontWeight:(index === 7 ? 'bold' : '')
												}}
												align={index === 7 ? 'right' : 'center'}
											>
												{Value}
											</TableCell>
										))}
									</TableHead>
									<TableHead>
										<TableCell sx={{ border: 1, width: 200, fontWeight: 'bold', padding: 0.5 }} align="left" variant="head">OTHER</TableCell>
										{Other.map((Value, index) => (
											<TableCell 
												sx={{ 
													border: 1
													, width: 100
													, padding: 0.5
													, fontWeight:(index === 7 ? 'bold' : '')
												}}
												align={index === 7 ? 'right' : 'center'}
											>
												{Value}
											</TableCell>
										))}
									</TableHead>
								</Table>
							</TableContainer>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default CancelItemTableService