import "./index.css";
import Router from "./Router";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

const vRoot = ReactDOM.createRoot( document.getElementById( "root" ) );

vRoot.render(
	<BrowserRouter>
		<div className="wrapper">
			<Router/>
		</div>
	</BrowserRouter>
);