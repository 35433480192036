/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { vBoolGolf, vBoolTravel, vGlobalDateNow } from '../Global'

const Footer = () => {
	return (
		<div>
			<footer className="main-footer">
				<strong>
					Copyright © {1900 + new Date( vGlobalDateNow ).getYear()}
					{vBoolTravel ?
						<Link to={`/`}>
							&nbsp;
							ITM
							&nbsp;
						</Link>
						:
							<>
							</>
					}
					{vBoolGolf ?
						<Link to={`/`}>
							&nbsp;
							GM360
							&nbsp;
						</Link>
						:
							<>
							</>
					}
				</strong>
				All rights reserved
				<div className="float-right d-none d-sm-inline-block">
					<b>
						Version
						&nbsp;
					</b>
					1.0.0
				</div>
			</footer>
		</div>
	)
}

export default Footer