import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, registerables  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format';
import { startOfMonth, endOfMonth } from 'date-fns';

ChartJS.register(...registerables )

const IncomeStatementChart = () => {
	const [IncomeData, SetIncomeData] = useState([]);
	const [loading, setLoading] = useState(false);

	const NewDate = new Date( vGlobalDateNow );
	const MonthEnd = endOfMonth( NewDate );
	const [ vSearchPlayerEndDate, SetSearchPlayerEndDate ] = useState(MonthEnd);
	const MonthStart = subtractMonths(startOfMonth(NewDate), 12);
	const [ vSearchPlayerStartDate, SetSearchPlayerStartDate ] = useState(MonthStart);

	function subtractMonths(date, months) {
		const newDate = new Date( date );
		newDate.setMonth(date.getMonth() - months);
		return newDate;
	}

	const renderMonthContent = (month, shortMonth, longMonth) => {
		return <span>{shortMonth}</span>;
	};

	const vNumberFormat = new Intl.NumberFormat(
		'en-US'
		, {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		}
	);

	// IncomeData.map(Value => vNumberFormat.format(Value.Revenue) )
	const DataTemp = {
		"Result": [
			{
				"Date": "Jan-2023",
				"Revenue": 1154,
				"Cost": 643,
				"Expense": 321
			},
			{
				"Date": "Feb-2023",
				"Revenue": 1212,
				"Cost": 688,
				"Expense": 347
			},
			{
				"Date": "Mar-2023",
				"Revenue": 1272,
				"Cost": 736,
				"Expense": 404
			},
			{
				"Date": "Apr-2023",
				"Revenue": 1399,
				"Cost": 834,
				"Expense": 528
			},
			{
				"Date": "May-2023",
				"Revenue": 1441,
				"Cost": 788,
				"Expense": 436
			},
			{
				"Date": "Jun-2023",
				"Revenue": 1484,
				"Cost": 842,
				"Expense": 471
			},
			{
				"Date": "Jul-2023",
				"Revenue": 1633,
				"Cost": 859,
				"Expense": 509
			},
			{
				"Date": "Aug-2023",
				"Revenue": 1666,
				"Cost": 876,
				"Expense": 529
			},
			{
				"Date": "Sep-2023",
				"Revenue": 1699,
				"Cost": 894,
				"Expense": 550
			},
			{
				"Date": "Oct-2023",
				"Revenue": 1869,
				"Cost": 930,
				"Expense": 572
			},
			{
				"Date": "Nov-2023",
				"Revenue": 1887,
				"Cost": 967,
				"Expense": 590
			},
			{
				"Date": "Dec-2023",
				"Revenue": 1906,
				"Cost": 1001,
				"Expense": 607
			},
			{
				"Date": "Jan-2024",
				"Revenue": 1334,
				"Cost": 746,
				"Expense": 463
			}
		]
	}
	
	const DataDummy = DataTemp.Result

	const GetIncomeData = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];
		try {
			const vURL = vAPIURL + "/Golf/IncomeStatement";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					StartDate: {
						Value: format( vSearchPlayerStartDate, 'dd-MMM-yyyy' ),
					},
					EndDate: {
						Value: format( vSearchPlayerEndDate, 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios 
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;
						for(var i = 0; i < vResponse.data.Message.length; i++){
							vMessage.push(vResponse.data.Message[i]);
						}
						if(vStatus === 1){
							SetIncomeData(vResponse.data.Output.Result)
						}
					}
				)
				.catch(
					(vError) => {
						vStatus = 2;
						vMessage.push("Error");
					}
				);
		}catch(vError){
			vStatus = 2;
			vMessage.push("Error");
		}
		if(vMessage.length > 0){
			if(vStatus === 1){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading(false)
	}

	let data = {
		labels: DataDummy.map(Value => Value.Date),
		datasets: [{
			label: 'Revenue',
			data: DataDummy.map(Value => Value.Revenue),
			backgroundColor: 'rgb(255, 192, 0)',
			borderColor: 'rgb(255, 192, 0)',
			borderWidth: 1,
		} , {
			label: 'Cost',
			data: DataDummy.map(Value => Value.Cost),
			backgroundColor: 'rgb(209, 37, 21)',
			borderColor: 'rgb(209, 37, 21)',
			borderWidth: 1,
			stack: '0'
		}, {
			label: 'Expense',
			data: DataDummy.map(Value => Value.Expense),
			backgroundColor: 'rgb(0, 176, 80)',
			borderColor: 'rgb(0, 176, 80)',
			borderWidth: 1,
			stack: '0'
		}]
	};

	let option = {
		maintainAspecRatio: false,
		responsive : true,
		scales: {
			x: {
				beginAtZero: true
			},
			y: {
				beginAtZero: true
			}
		},
		plugins: {
			legend: {
				display: true,
				position: 'top',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'true', 
				formatter: function( value, context ){
					return value;
				}
			}
		}
	} 

	useEffect(() => {
		GetIncomeData()
	}, [])

	const verticalTextStyle = {
		transform: 'rotate(-90deg)',
		whiteSpace: 'nowrap',
		fontWeight:'bold'
	};
	
	return (
		<>
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						INCOME STATEMENT
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
							</li>
						</ul>
					</div>
				</div>
				<div className="card-body">
					{/* <div className="row">
						<div className="col-sm-3">
							<div className="form-group">
								<label>
									Start Date
								</label>
								<DatePicker
									dateFormat="MMM-yyyy"
									renderMonthContent={renderMonthContent}
									showMonthYearPicker
									selected={vSearchPlayerStartDate}
									onChange={e => SetSearchPlayerStartDate(startOfMonth(e))}
									className="form-control"
									value={vSearchPlayerStartDate}
								/>
							</div>
						</div>
						<div className="col-sm-3">
							<div className="form-group">
								<label>
									End Date
								</label>
								<DatePicker
									dateFormat="MMM-yyyy"
									selected={vSearchPlayerEndDate}
									renderMonthContent={renderMonthContent}
									showMonthYearPicker
									onChange={e => SetSearchPlayerEndDate(endOfMonth(e))}
									className="form-control"
									value={vSearchPlayerEndDate}
								/>
							</div>
						</div>
						<div className="col-sm-6">
							<button
								onClick={GetIncomeData}
								type="submit"
								className="btn btn-primary float-right"
								style={{ marginTop:"31px" }}
							>
								<i className="fas fa-search"/>
								&nbsp;
								{loading ?
										"Please wait..."
									:
										"Search"
								}
							</button>
						</div>
					</div> */}
					<div className="tab-content p-0 ">
						{loading ?
							<div className="d-flex justify-content-center">
								<i className="fas fa-3x fa-sync-alt fa-spin"></i>
							</div>
							:
							<>
								<div className="row">
									<div className="col-xl-12">
										<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 16 }}>
											INCOME STATEMENT
										</div>
										<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 12 }}>
											(In Million)
										</div>
									</div>
									<div className="col-xl-1 d-flex align-items-center">
										<div className="d-flex justify-content-center" style={verticalTextStyle}>
											AMOUNT
										</div>
									</div>
									<div className="col-xl-11">
										<Bar
											data={data}
											plugins={[ChartDataLabels]}
											options={option}
											height={150}
										/>
									</div>
									<div className="col-xl-12">
										<div className="d-flex justify-content-center" style={{ fontWeight:'bold', padding: '10px' }}>
											PERIOD
										</div>
										<TableContainer>
											<Table>
												<TableHead>
													<TableCell sx={{ width: 100, border: 1, padding: 0.5 }} align="left" variant="head"></TableCell>
														{DataDummy.map((Value) => (
															<TableCell sx={{ width: 200, border: 1, padding: 0.5 }} align="center" variant="head">{Value.Date}</TableCell>
														))}
												</TableHead>
												<TableHead>
													<TableCell sx={{ width: 100, border: 1, padding: 0.5 }} align="left" variant="head">REVENUE</TableCell>
														{DataDummy.map((Value) => (
															<TableCell sx={{ width: 200, border: 1, padding: 0.5  }} align="right" variant="head">{vNumberFormat.format(Value.Revenue)}</TableCell>
														))}
												</TableHead>
												<TableHead>
													<TableCell sx={{ width: 100, border: 1, padding: 0.5 }} align="left" variant="head">COST</TableCell>
														{DataDummy.map((Value) => (
															<TableCell sx={{ width: 200, border: 1, padding: 0.5   }} align="right" variant="head">({vNumberFormat.format(Value.Cost)})</TableCell>
														))}
												</TableHead>
												<TableHead>
													<TableCell sx={{ width: 100, border: 1, padding: 0.5 }} align="left" variant="head">EXPENSE</TableCell>
														{DataDummy.map((Value) => (
															<TableCell sx={{ width: 200, border: 1, padding: 0.5 }} align="right" variant="head">({vNumberFormat.format(Value.Expense)})</TableCell>
														))}
												</TableHead>
												<TableHead>
													<TableCell sx={{ width: 100, border: 1, padding: 0.5 }} align="left" variant="head"></TableCell>
														{DataDummy.map((Value) => (
															<TableCell sx={{ width: 200, border: 1, padding: 0.5, fontWeight: 'bold', fontStyle: 'italic'}} align="right" variant="head">{vNumberFormat.format(Value.Revenue - Value.Cost - Value.Expense)}</TableCell>
														))}
												</TableHead>
											</Table>
										</TableContainer>
									</div>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default IncomeStatementChart