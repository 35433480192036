import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import TablePaginationUnstyled, { tablePaginationUnstyledClasses as classes } from '@mui/base/TablePaginationUnstyled';
import { styled } from '@mui/system';
import { startOfWeek, format } from 'date-fns';

const TopSellingTableFnB = () => {
	const [TopSalesData, SetTopSalesData] = useState([])
	const [loading, setLoading] = useState(false)
	let StartDate = format( startOfWeek( new Date( vGlobalDateNow ), { weekStartsOn: 1 } ), 'dd MMM' )
	let EndDate = format( new Date( vGlobalDateNow ), 'dd MMM' )

	const GetTopItemTable = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Golf/FnB/TopSelling";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;
						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}
						if( vStatus === 1 ){
							SetTopSalesData( vResponse.data.Output.Result )
						}
					}
				)
				.catch(
					( vError ) => {
						vStatus = 2;
						vMessage.push( "Error" );
					}
				);
		}catch( vError ){
			vStatus = 2;
			vMessage.push( "Error" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading( false );
	}

	useEffect(() => {
		GetTopItemTable()
	}, [])

	let DataFoodTemp = [];
	let SumQtyFood = 0;

	TopSalesData.map(Header => {
		if( typeof Header.FOOD !== 'undefined' ){
			Header.FOOD.map(Content => {
				DataFoodTemp.push(Content)
				SumQtyFood += parseInt(Content.TotalQty)
			})
		}
		else{
			SumQtyFood = 0
		}
	})

	function FormatDataFood(MenuName, TotalQty, Ordering, Progress) {
		Progress = (Math.round((TotalQty - 0) * 100) / (SumQtyFood - 0)).toFixed(1);
		return {MenuName, TotalQty, Ordering, Progress};
	}

	const DataFood = DataFoodTemp.map((Data, Index) => {
		const Ordering = Index + 1
		const {MenuName, TotalQty} = Data;
		return FormatDataFood(MenuName, TotalQty, Ordering);
		}
	)

	let DataBeverageTemp = [];
	let SumQtyBeverage = 0;

	TopSalesData.map(Header => {
		if( typeof Header.BEVERAGE !== 'undefined' ){
			Header.BEVERAGE.map(Content => {
				DataBeverageTemp.push(Content)
				SumQtyBeverage += parseInt(Content.TotalQty)
			})
		}
		else{
			SumQtyBeverage = 0
		}
	})

	function FormatDataBeverage(MenuName, TotalQty, Ordering, Progress) {
		Progress = (Math.round((TotalQty - 0) * 100) / (SumQtyBeverage - 0)).toFixed(1);
		return {MenuName, TotalQty, Ordering, Progress};
	}

	const DataBeverage = DataBeverageTemp.map((Data, Index) => {
		const Ordering = Index + 1
		const {MenuName, TotalQty} = Data;
		return FormatDataBeverage(MenuName, TotalQty, Ordering);
		}
	)

	const CustomTablePagination = styled(TablePaginationUnstyled)`
		& .${classes.toolbar} {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 7px;

			@media (min-width: 768px) {
				flex-direction: row;
				align-items: center;
			}
		}

		& .${classes.selectLabel} {
			margin: 0;
			gap: 7px;
		}

		& .${classes.displayedRows} {
			margin: 0;
			gap: 7px;

			@media (min-width: 768px) {
				margin-left: auto;
			}
		}

		& .${classes.spacer} {
			display: none;
		}

		& .${classes.actions} {
			display: flex;
			gap: 0.25rem;
		}
	`;

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [StartCount, SetStartCount] = useState(1);
	const [EndCount, SetEndCount] = useState(rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		if ( page < newPage ){
			if ( page + 1 == newPage ){
				SetStartCount((StartCount + rowsPerPage));
				if ( (EndCount + rowsPerPage) > ArrayLength ){
					SetEndCount(ArrayLength);
				}
				else{
					SetEndCount((EndCount + rowsPerPage));
				}
			}
			else{
				SetStartCount( (newPage * rowsPerPage) + 1 );
				SetEndCount(ArrayLength);
			}
		}
		else if ( page > newPage ){
			if ( page - 1 == newPage ){
				SetStartCount((StartCount - rowsPerPage));
				if ( (newPage + 1) * rowsPerPage > ArrayLength ){
					SetEndCount(ArrayLength);
				}
				else{
					SetEndCount((newPage + 1) * rowsPerPage);
				}
			}
			else{
				SetStartCount(1);
				SetEndCount(rowsPerPage);
			}
		}
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value));
		SetStartCount(1);
		if (event.target.value == -1){
			SetEndCount(ArrayLength);
		}
		else{
			SetEndCount(parseInt(event.target.value));
		}
		setPage(0);
	};

	let ArrayLength = 0;
	if (DataFood.length >= DataBeverage.length){
		ArrayLength = DataFood.length
	}
	else{
		ArrayLength = DataBeverage.length
	}

	const DataBeverageTemp2 = (rowsPerPage > 0 
		? DataBeverage.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		: DataBeverage
	)

	let SumBeverage2 = 0;
	let PercentageBeverage = 0;

	DataBeverageTemp2.map(Value => {
		SumBeverage2 += parseInt(Value.TotalQty)
	})

	PercentageBeverage = (Math.round((SumBeverage2 - 0) * 100) / (SumQtyBeverage - 0)).toFixed(1)

	if ( isNaN(PercentageBeverage) ){
		PercentageBeverage = 0
	}
	
	const DataFoodTemp2 = (rowsPerPage > 0 
		? DataFood.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		: DataFood
	)
	
	let SumFood2 = 0;
	let PercentageFood = 0;

	DataFoodTemp2.map(Value => {
		SumFood2 += parseInt(Value.TotalQty)
	})

	PercentageFood = (Math.round((SumFood2 - 0) * 100) / (SumQtyFood - 0)).toFixed(1)
	
	if ( isNaN(PercentageFood) ){
		PercentageFood = 0
	}

	return (
		<div className="row p-1">
			<div className="col-12 col-lg-6 p-1">
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								WEEK TO DATE FBS TOP SELLING FOOD
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<div className="text-info">
							{StartDate} - {EndDate}
						</div>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item">
								<CustomTablePagination
									rowsPerPageOptions={[5, 10, 20, { label: 'All', value: -1 }]}
									colSpan={4}
									count={ArrayLength}
									rowsPerPage={rowsPerPage}
									page={page}
									slotProps={{
										select: {
										'aria-label': 'rows per page',
										},
										actions: {
											showFirstButton: true,
											showLastButton: true,
										},
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</li>
						</ul>
					</div>
					{
						loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div>
							<TableContainer>
								<Table>
									<TableHead sx={{ minHeight:10 }}>
										<TableRow>
											<TableCell sx={{ border: 1, padding:0.5 , width: 30, fontWeight: 'bold' }} align="left">#</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 , minWidth: 150, fontWeight: 'bold' }} align="center">FOOD</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 , width: 83, fontWeight: 'bold' }} align="center">QTY</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 , width: 83, fontWeight: 'bold' }} align="center">%</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{(rowsPerPage > 0
											? DataFood.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											: DataFood
											).map(Value2 => (
											<TableRow>
												<TableCell sx={{ border: 1, padding:0.5 }} align="center">{Value2.Ordering}</TableCell>
												<TableCell sx={{ border: 1, padding:0.5 }} align="left">{Value2.MenuName}</TableCell>
												<TableCell sx={{ border: 1, padding:0.5 }} align="center">{Value2.TotalQty}</TableCell>
												<TableCell sx={{ border: 1, padding:0.5 }} align="center">{Value2.Progress} %</TableCell>
											</TableRow>
										))}
									</TableBody>
									<TableBody>
										<TableRow>
											<TableCell sx={{ border: 1, padding:0.5, fontWeight: 'bold' }} colSpan={2} align="left">TOTAL FOOD</TableCell>
											<TableCell sx={{ border: 1, padding:0.5, fontWeight: 'bold' }} align="center">{SumQtyFood}</TableCell>
											<TableCell sx={{ border: 1, padding:0.5, fontWeight: 'bold' }} align="center">{PercentageFood} %</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					}
				</div>
			</div>
			<div className="col-12 col-lg-6 p-1">
				<div className="card bg-gradient-light">
					<div className="card-header">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
								WEEK TO DATE FBS TOP SELLING BEVERAGE
							</li>
						</ul>
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info">
								(In Qty)
							</li>
						</ul>
						<div className="text-info">
							{StartDate} - {EndDate}
						</div>
						<ul className="nav nav-pills ml-auto" style={{ height: 29 }}>
							<li className="nav-item justify-content-center" style={{ paddingTop: 5 }}>
								Rows per page: {StartCount} - {EndCount} of {ArrayLength}
							</li>
						</ul>
					</div>
					{
						loading ?
						<div className="d-flex justify-content-center">
							<i className="fas fa-3x fa-sync-alt fa-spin"></i>
						</div>
						:
						<div>
							<TableContainer>
								<Table>
									<TableHead sx={{ minHeight:10 }}>
										<TableRow>
											<TableCell sx={{ border: 1, padding:0.5 , width: 30, fontWeight: 'bold' }}>#</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 , minWidth: 150, fontWeight: 'bold' }} align="center">BEVERAGE</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 , width: 83, fontWeight: 'bold' }} align="center">QTY</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 , width: 83, fontWeight: 'bold' }} align="center">%</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
									{(rowsPerPage > 0
										? DataBeverage.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: DataBeverage
									).map(Value2 => (
										<TableRow sx={{ border: 1 }}>
											<TableCell sx={{ border: 1, padding:0.5 }} align="center">{Value2.Ordering}</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 }} align="left">{Value2.MenuName}</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 }} align="center">{Value2.TotalQty}</TableCell>
											<TableCell sx={{ border: 1, padding:0.5 }} align="center">{Value2.Progress} %</TableCell>
										</TableRow> 
									))}
									</TableBody>
									<TableBody>
										<TableRow sx={{ border: 1 }}>
											<TableCell sx={{ border: 1, padding:0.5, fontWeight: 'bold' }} colSpan={2} align="left">TOTAL BEVERAGE</TableCell>
											<TableCell sx={{ border: 1, padding:0.5, fontWeight: 'bold' }} align="center">{SumQtyBeverage}</TableCell>
											<TableCell sx={{ border: 1, padding:0.5, fontWeight: 'bold' }} align="center">{PercentageBeverage} %</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						}
				</div>
			</div>
		</div>
	);
}

export default TopSellingTableFnB