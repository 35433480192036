import React, { useEffect, useState, useRef } from 'react';
import { Chart as ChartJS, registerables  } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { vAPIURL, vGlobalDateNow } from '../../Global';
import axios from 'axios';
import toastr from 'toastr';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import format from 'date-fns/format';
import { startOfMonth } from 'date-fns';

ChartJS.register(...registerables )

const BalanceSheetPieChart = () => {
	const [FixedAssetData, SetFixedAssetData] = useState([]);
	const [LiabiltyData, SetLiabilityData] = useState([]);
	const [loading, setLoading] = useState(false);
	
	const NewDate = new Date( vGlobalDateNow );
	const MonthStart = startOfMonth(NewDate);
	const [Period, SetPeriod] = useState(MonthStart);
	const renderMonthContent = (month, shortMonth, longMonth) => {
		return <span>{shortMonth}</span>;
	};

	const vNumberFormat = new Intl.NumberFormat(
		'en-US'
		, {
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		}
	);

	const GetFixedAsset = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];
		try {
			const vURL = vAPIURL + "/Golf/BalanceSheet/Asset";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Period: {
						Value : format( Period, 'MMM-yyyy' )
					}
				}
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios 
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;
						for(var i = 0; i < vResponse.data.Message.length; i++){
							vMessage.push(vResponse.data.Message[i]);
						}
						if(vStatus === 1){
							SetFixedAssetData(vResponse.data.Output.Result)
						}
					}
				)
				.catch(
					(vError) => {
						vStatus = 2;
						vMessage.push("Error");
					}
				);
		}catch(vError){
			vStatus = 2;
			vMessage.push("Error");
		}
		if(vMessage.length > 0){
			if(vStatus === 1){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading(false)
	}

	const GetLiability = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];
		try {
			const vURL = vAPIURL + "/Golf/BalanceSheet/Liability";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Period: {
						Value : format( Period, 'MMM-yyyy' )
					}
				}
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};
			await axios 
				.post(vURL, vData, vConfig)
				.then(
					(vResponse) => {
						vStatus = vResponse.data.Status;
						for(var i = 0; i < vResponse.data.Message.length; i++){
							vMessage.push(vResponse.data.Message[i]);
						}
						if(vStatus === 1){
							SetLiabilityData(vResponse.data.Output.Result)
						}
					}
				)
				.catch(
					(vError) => {
						vStatus = 2;
						vMessage.push("Error");
					}
				);
		}catch(vError){
			vStatus = 2;
			vMessage.push("Error");
		}
		if(vMessage.length > 0){
			if(vStatus === 1){
				//toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}
		setLoading(false)
	}

	const FixedTemp = {
		"Result": [
			{
				"Name": "FIXED ASSET",
				"Amount": 6478.7,
				"Percentage": '66.3'
			},
			{
				"Name": "NON-CURRENT ASSETS",
				"Amount": 4069.4,
				"Percentage": '33.7'
			},
			{
				"Name": "TOTAL",
				"Amount": 10549.1,
				"Percentage": ''
			}
		]
	}

	const LiabilityTemp = {
		"Result": [
			{
				"Name": "LIABILITIES",
				"Amount": 3692.2,
				"Percentage": '35.0'
			},
			{
				"Name": "EQUITY",
				"Amount": 6856.9,
				"Percentage": '65.0'
			},
			{
				"Name": "TOTAL",
				"Amount": 10549.1,
				"Percentage": ''
			}
		]
	}
	
	const DummyFixed = FixedTemp.Result
	const DummyLiability = LiabilityTemp.Result

	const Color = [
		'rgba(255, 99, 132, 0.5)',
		'rgba(3, 252, 227, 0.5)',
		'rgba(3, 252, 15, 0.5)',
		'rgba(252, 252, 3, 0.5)',
		'rgba(252, 136, 3, 0.5)',
		'rgba(24, 3, 252, 0.5)',
		'rgba(250, 50, 193, 0.5)'
	]

	const LiabiltyDataChart = {
		labels: DummyLiability.map(Value => Value.Name).slice( 0, -1 ),
		datasets: [{
			label: 'Revenue',
			data: DummyLiability.map(Value => Value.Amount).slice( 0, -1 ),
			backgroundColor: Color,
			borderColor: Color,
			borderWidth: 1,
		}]
	};

	const FixedAssetDataChart = {
		labels: DummyFixed.map(Value => Value.Name).slice( 0, -1 ),
		datasets: [{
			label: 'Revenue',
			data: DummyFixed.map(Value => Value.Amount).slice( 0, -1 ),
			backgroundColor: Color,
			borderColor: Color,
			borderWidth: 1,
		}]
	};

	const option = {
		maintainAspecRatio: false,
		responsive : true,
		plugins: {
			legend: {
				display: false,
				position: 'bottom',
				align: 'center',
				labels: {
					boxHeight: 5,
					boxWidth: 10,
					textAlign: 'left'
				}
			},
			datalabels: {
				anchor: 'center',
				align: 'center',
				display: 'true'
			}
		}
	}

	const DotList = ({ dotIndex, totalDots }) => {
		const Color = [
			'rgba(255, 99, 132, 0.5)',
			'rgba(3, 252, 227, 0.5)',
			'rgba(3, 252, 15, 0.5)',
			'rgba(252, 252, 3, 0.5)',
			'rgba(252, 136, 3, 0.5)',
			'rgba(24, 3, 252, 0.5)',
			'rgba(250, 50, 193, 0.5)'
		];
	  
		const dotStyle = {
			width: '10px',
			height: '10px',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '5px'
		};
	  
		if (dotIndex !== totalDots - 1) {
			return (
				<span
					style={{
					...dotStyle,
					backgroundColor: Color[dotIndex]
					}}
				>
				</span>
				);
			} else {
				return null;
		}
	};

	useEffect(() => {
		GetFixedAsset()
		GetLiability()
	}, [])
	
	return (
		<div className="col-xl-12">
			<div className="card bg-gradient-light">
				<div className="card-header">
					<h3 className="card-title text-success font-weight-bold">
						BALANCE SHEET
					</h3>
					<div className="card-tools">
						<ul className="nav nav-pills ml-auto">
							<li className="nav-item text-info font-weight-bold">
							</li>
						</ul>
					</div>
				</div>
				{/* <div className="row p-3">
					<div className="col-sm-3">
						<div className="form-group">
							<label>
								Period
							</label>
							<DatePicker
								dateFormat="MMM-yyyy"
								renderMonthContent={renderMonthContent}
								showMonthYearPicker
								selected={Period}
								onChange={e => SetPeriod(startOfMonth(e))}
								className="form-control"
								value={Period}
							/>
						</div>
					</div>
					<div className="col-sm-9">
						<button
							onClick={() => {
								GetFixedAsset();
								GetLiability();
							}}
							type="submit"
							className="btn btn-primary float-right"
							style={{ marginTop:"31px" }}
						>
							<i className="fas fa-search"/>
							&nbsp;
							{loading ?
									"Please wait..."
								:
									"Search"
							}
						</button>
					</div>
				</div> */}
				<div className="row">
					<div className="col-xl-6">
						<div className="card-body">
							<div className="tab-content p-0 ">
								{loading ?
									<div className="d-flex justify-content-center">
										<i className="fas fa-3x fa-sync-alt fa-spin"></i>
									</div>
									:
									<>
										<div className="row">
											<div className="col-xl-12">
												<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 16 }}>
													BALANCE SHEET
												</div>
												<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 16 }}>
													ASSETS
												</div>
												<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 12 }}>
													(In Million)
												</div>
												<Pie
													data={FixedAssetDataChart}
													height={150}
													options={option}
													plugins={[ChartDataLabels]}
												/>
												<div style={{ padding: '10px' }}>
													<TableContainer>
														<Table>
															{DummyFixed.map((Value, Index) => (
																<TableHead>
																	{
																		Index == 2
																			?
																			<TableCell 
																				sx={{
																					width: 200
																					, border: 1
																					, padding: 0.5
																					, fontWeight: 'bold'
																				}}
																				align="left"
																			>
																				<DotList dotIndex={Index} totalDots={DummyFixed.length} />
																				&nbsp; {Value.Name}
																			</TableCell>
																			:
																			<TableCell 
																				sx={{
																					width: 200
																					, border: 1
																					, padding: 0.5
																				}}
																				align="left"
																			>
																				<DotList dotIndex={Index} totalDots={DummyFixed.length} />
																				&nbsp; {Value.Name}
																			</TableCell>
																	}
																	{
																		Index == 2
																			?
																			<TableCell
																				sx={{
																					width: 100
																					, border: 1
																					, padding: 0.5
																					, fontWeight: 'bold'
																				}}
																				align="right"
																			>
																				{vNumberFormat.format(Value.Amount)}
																			</TableCell>
																			:
																			<TableCell
																				sx={{
																					width: 100
																					, border: 1
																					, padding: 0.5
																				}}
																				align="right"
																			>
																				{vNumberFormat.format(Value.Amount)}
																			</TableCell>
																	}
																	{
																		Index == 2
																			?
																			<TableCell 
																				sx={{
																					width: 100
																					, border: 1
																					, padding: 0.5
																				}}
																				align="right"
																			>
																				{Value.Percentage}
																			</TableCell>
																			:
																			<TableCell 
																				sx={{
																					width: 100
																					, border: 1
																					, padding: 0.5
																				}}
																				align="right"
																			>
																				{Value.Percentage} %
																			</TableCell>
																	}
																</TableHead>
															))}
														</Table>
													</TableContainer>
												</div>
											</div>
										</div>
									</>
								}
							</div>
						</div>
					</div>
					<div className="col-xl-6">
						<div className="card-body">
							<div className="tab-content p-0 ">
								{loading ?
									<div className="d-flex justify-content-center">
										<i className="fas fa-3x fa-sync-alt fa-spin"></i>
									</div>
									:
									<>
										<div className="row">
											<div className="col-xl-12">
												<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 16 }}>
													BALANCE SHEET
												</div>
												<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 16 }}>
													LIABILITY & EQUITY
												</div>
												<div className="d-flex justify-content-center" style={{ fontWeight:'bold', fontSize: 12 }}>
													(In Million)
												</div>
												<Pie
													data={LiabiltyDataChart}
													height={150}
													options={option}
													plugins={[ChartDataLabels]}
												/>
												<div style={{ padding: '10px' }}>
													<TableContainer>
														<Table>
															{DummyLiability.map((Value, Index) => (
																<TableHead>
																	{
																		Index == 2
																			?
																			<TableCell 
																				sx={{
																					width: 200
																					, border: 1
																					, padding: 0.5
																					, fontWeight: 'bold'
																				}}
																				align="left"
																			>
																				<DotList dotIndex={Index} totalDots={DummyLiability.length} />
																				&nbsp; {Value.Name}
																			</TableCell>
																			:
																			<TableCell 
																				sx={{
																					width: 200
																					, border: 1
																					, padding: 0.5
																				}}
																				align="left"
																			>
																				<DotList dotIndex={Index} totalDots={DummyFixed.length} />
																				&nbsp; {Value.Name}
																			</TableCell>
																	}
																	{
																		Index == 2
																			?
																			<TableCell
																				sx={{
																					width: 100
																					, border: 1
																					, padding: 0.5
																					, fontWeight: 'bold'
																				}}
																				align="right"
																			>
																				{vNumberFormat.format(Value.Amount)}
																			</TableCell>
																			:
																			<TableCell
																				sx={{
																					width: 100
																					, border: 1
																					, padding: 0.5
																				}}
																				align="right"
																			>
																				{vNumberFormat.format(Value.Amount)}
																			</TableCell>
																	}
																	{
																		Index == 2 
																			?
																			<TableCell
																				sx={{
																					width: 100
																					, border: 1
																					, padding: 0.5
																				}}
																				align="right"
																			>
																				{Value.Percentage}
																			</TableCell>
																			:
																			<TableCell 
																				sx={{ 
																					width: 100
																					, border: 1
																					, padding: 0.5
																				}}
																				align="right"
																			>
																				{Value.Percentage} %
																			</TableCell>
																	}
																</TableHead>
															))}
														</Table>
													</TableContainer>
												</div>
											</div>
										</div>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BalanceSheetPieChart