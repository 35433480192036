import "toastr/build/toastr.min.css";
import axios from "axios";

import Golf_BG from './../Assets/Golf_BG.jpg';
import Travel_BG from './../Assets/Travel_BG.png';
import Askara_BG from './../Assets/Askara_BG.png';

import Golf_Logo from './../Assets/Golf_Logo.png';
import Travel_Logo from './../Assets/Travel_Logo.jpg';
import Askara_Logo from './../Assets/Askara_Logo.png';

import { vAPIURL, vBoolGolf, vBoolTravel } from './../Global';

import React, { useState } from "react";
import toastr from "toastr";
import { Button, TextField, Typography } from "@mui/material";

const SignOTP = () => {
	const [ vLoading, SetLoading ] = useState( false );
	const [ vOTP, SetOTP ] = useState( "" );

	let vBG = Askara_BG;
	let vLogo = Askara_Logo;
	let vTitle = "ASKARA";

	if( vBoolGolf && vBoolTravel ){
	}
	else if( vBoolGolf ){
		vBG = Golf_BG;
		vLogo = Golf_Logo;
		vTitle = "GM360";
	}
	else if( vBoolTravel ){
		vBG = Travel_BG;
		vLogo = Travel_Logo;
		vTitle = "ITM";
	}

	const FSignOTP = async () => {
		SetLoading( true );
		let vStatus = 1;
		let vMessage = [];

		try{
			const vURL = vAPIURL + "/Sign/OTP";
			const vData = {
				Action: {
					Value: "Confirm",
				},
				Input: {
					OTP: {
						Value: vOTP,
					}
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${ localStorage.getItem( "Authentication" ) }`,
				},
			};

			await axios
				.post( vURL, vData, vConfig )
				.then(
					( vResponse ) => {
						vStatus = vResponse.data.Status;

						for( var i = 0; i < vResponse.data.Message.length; i++ ){
							vMessage.push( vResponse.data.Message[ i ] );
						}

						if( vResponse.data.Session.Sign !== undefined && vResponse.data.Session.Sign !== null ){
							if( vResponse.data.Session.Sign === 2 ){
								localStorage.clear();
								window.location.replace("/");								
							}
						}

						if( vStatus === 1 ){
							window.location.replace( "/" );
						}
					}
				)
				.catch(
					( err ) => {
						vStatus = 2;
						vMessage.push( "Error System" );
					}
				);
		}catch( error ){
			vStatus = 2;
			vMessage.push( "Error System" );
		}

		if( vMessage.length > 0 ){
			if( vStatus === 1 ){
				toastr.success( vMessage.join( "</br>" ) );
			}
			else{
				toastr.error( vMessage.join( "</br>" ) );
			}
		}

		SetLoading( false );
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundImage: `url(${vBG})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				height: "100vh"
			}}
		>
			<div align="center">
				<div className="login-box">
					<div className="card card-outline card-primary">
						<div className="card-header text-center">
							<div className="row">
								<div className="col-2">
									<img
										src={vLogo}
										className="brand-image img-circle elevation-3"
										style={{ height: 60, opacity: ".8" }}
									/>
								</div>
								<div className="col-8">
									<Typography sx={{ mt: 2 }} variant="h5">
										{vTitle} Dashboard
									</Typography>
								</div>
							</div>
						</div>
						<div className="card-body">
							<h6>
								Confirm OTP
							</h6>
							Please check the One-Time Password (OTP) sent to your WhatsApp number
							<TextField
								variant="outlined"
								margin="normal"
								default=""
								fullWidth
								id="OTP"
								name="OTP"
								label="OTP"
								type="text"
								onChange={ ( e ) => SetOTP( e.target.value ) }
							/>
							{
								vLoading
								?
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
									>
										<center>
											<i className="fas fa-1x fa-sync-alt fa-spin">
											</i>
										</center>
									</Button>
								:
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										onClick={FSignOTP}
									>
										Confirm
									</Button>
							}
							<div
								style={{ marginTop: "5px" }}
							>
							</div>
							{
								vLoading ?
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="error"
									>
										<center>
											<i className="fas fa-1x fa-sync-alt fa-spin">
											</i>
										</center>
									</Button>
								:
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="error"
										onClick={(e) => window.location.replace("/Sign/Out")}
									>
										Sign Out
									</Button>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignOTP;