import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box } from "@mui/material";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import axios from "axios";
import { vAPIURL, vBoolDev, vGlobalDateNow } from "../../Global";
import toastr from "toastr";
import { startOfMonth, format } from 'date-fns';

const WaitersRank = () => {
	const [WaitersRank, SetWaitersRank] = useState([])
	const [loading, setLoading] = useState(false)
	let StartDate = format( startOfMonth( new Date( vGlobalDateNow ) ), 'dd MMM yyyy' )
	let EndDate = format( new Date( vGlobalDateNow ), 'dd MMM yyyy' )

	const GetWaitersRank = async () => {
		setLoading(true);
		let vStatus = 1;
		let vMessage = [];

		try {
			const vURL = vAPIURL + "/Golf/FnB/WaiterRank";
			const vData = {
				Action: {
					Value: "Search",
				},
				Input: {
					Date: {
						Value: format( new Date( vGlobalDateNow ) , 'dd-MMM-yyyy' ),
					},
				},
			};
			const vConfig = {
				headers: {
					"Content-Type": "application/json",
					Authentication: `${localStorage.getItem("Authentication")}`,
				},
			};

			await axios
				.post(vURL, vData, vConfig)
				.then((vResponse) => {
					vStatus = vResponse.data.Status;

					for (var i = 0; i < vResponse.data.Message.length; i++) {
						vMessage.push(vResponse.data.Message[i]);
					}

					if (vStatus === 1) {
						SetWaitersRank(vResponse.data.Output.Result);
					}
				})
				.catch((vError) => {
					vStatus = 2;
					vMessage.push("Error");
				});
		} catch (vError) {
			vStatus = 2;
			vMessage.push("Error");
		}

		if (vMessage.length > 0) {
			if (vStatus === 1) {
				//toastr.success( vMessage.join( "</br>" ) );
			} else {
				toastr.error(vMessage.join("</br>"));
			}
		}

		setLoading(false);
	}

	useEffect(() => {
		GetWaitersRank()
	}, [])

	const grouped = Object.values(
		WaitersRank.reduce((Sorted, DataRows) => {
			Sorted[DataRows.Location] = [
				...(Sorted[DataRows.Location] || []),
				DataRows,
			];
			return Sorted;
		}, {})
	)

	let Data1 = [];
	grouped.forEach((element) => {
		const NameTemp = [];
		let LocationTemp = "";
		let QtyTemp = [];
		let RevenueTemp = [];
		let URLProfilePictureTemp =[];
		let OrderTemp = "";
		
		let SumRevenuePerLocation = 0;

		element.forEach((Group) => {
			LocationTemp = Group.Location;
			OrderTemp = Group.Order;
			NameTemp.push(Group.Name);
			QtyTemp.push(Group.Qty);
			RevenueTemp.push(Group.TotalRevenue);
			URLProfilePictureTemp.push(Group.URLProfilePicture)
			SumRevenuePerLocation += parseFloat(Group.TotalRevenue);
		});

		Data1.push({
			Order: OrderTemp,
			Location: LocationTemp,
			Name: NameTemp,
			Qty: QtyTemp,
			Revenue: RevenueTemp,
			TotalRevenue: SumRevenuePerLocation,
			URLProfilePicture: URLProfilePictureTemp
		});
	})

	Data1 = [...Data1].sort((a, b) => parseInt(a.Order) - parseInt(b.Order))

	return (
		<div className="card bg-gradient-light">
			<div className="card-header">
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info font-weight-bold">
						MONTH TO DATE WAITERS RANK
					</li>
				</ul>
				<ul className="nav nav-pills ml-auto">
					<li className="nav-item text-info">
						(In Qty)
					</li>
				</ul>
				<div className="text-info">
					{StartDate} - {EndDate}
				</div>
			</div>
			{
				loading ? 
				<div className="d-flex justify-content-center">
					<i className="fas fa-3x fa-sync-alt fa-spin"></i>
				</div>
				:
				<div>
					<TableContainer component={Paper}>
						<Table aria-label="Waiters Label">
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: 200, fontWeight: "bold" }}>
										LOCATION
									</TableCell>
									<TableCell sx={{ fontWeight: "bold" }} colSpan={4} align="left">
										TEAM F&B
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Data1.map((row) => (
									<TableRow key={row.Location}>
										{
											vBoolDev == true ?
											<TableCell>
												{row.Location.replace("SUVARNA PRACTICE RANGE", "DRIVING RANGE")}
											</TableCell>
											:
											<TableCell>
												{row.Location}
											</TableCell>
										}
										<TableCell sx={{ width: 75 }}>
											{row.URLProfilePicture.map((Url) => (
												<Avatar sx={{ margin: 1 }} src={Url}/>
											)).slice(0, 3)}
										</TableCell>
										<TableCell sx={{ width: 300 }}>
											{row.Name.map((WaiterName, index) => (
												<Box sx={{ borderBottom: 1 }}>
													{WaiterName}
													{index === 0 ? (
														<>
															{" "}
															<StarOutlineIcon />
														</>
													) : null}
													<br />
													<DescriptionOutlinedIcon />
													{row.Qty[index]} &emsp; <MonetizationOnOutlinedIcon />
													{(Math.round(((row.Revenue[index] - 0) * 100) / (row.TotalRevenue - 0) * 100) / 100).toFixed(1)}%
													<br />
												</Box>
											)).slice(0, 3)}
										</TableCell>
										<TableCell sx={{ width: 75 }}>
											{row.URLProfilePicture.map((Url) => (
												<Avatar sx={{ margin: 1 }} src={Url}/>
											)).slice(3, 6)}
										</TableCell>
										<TableCell sx={{ width: 300 }}>
											{row.Name.map((WaiterName, index) => (
												<Box sx={{ borderBottom: 1 }}>
													{WaiterName}
													{index === 0 ? (
														<>
															{" "}
															<StarOutlineIcon />
														</>
													) : null}
													<br />
													<DescriptionOutlinedIcon />
													{row.Qty[index]} &emsp; <MonetizationOnOutlinedIcon />
													{(Math.round(((row.Revenue[index] - 0) * 100) / (row.TotalRevenue - 0) * 100) / 100).toFixed(1)}%
													<br />
												</Box>
											)).slice(3, 6)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			}
		</div>
	);
};

export default WaitersRank;